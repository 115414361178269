<template>
  <p v-if="isLoading">Loading...</p>
  <p v-else-if="hasError">{{ error }}</p>
  <template v-else>
    <div class="controls">
      <div class="generate-area">
        <label>Reference Date</label>
        <datepicker v-model="rundate" />
        <button @click="getReport">Generate</button>
      </div>
      <button @click="download">Download</button>
    </div>
    <div class="content">
      <table class="report-table">
        <thead>
          <tr>
            <th v-for="col in columns" :key="col">{{ col }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="provider in providers" :key="provider.id">
            <td v-for="col in columns" :key="col">{{ provider[col] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>

<script lang="ts">
import { computed, onMounted, reactive, ref } from "vue";
import ApiInterface from "@/api/ApiInterface";
import { ProviderWeeklyReport } from "@/api/dto/WeeklyReport";
import { parse } from "json2csv";
import FileSaver from "file-saver";
import datepicker from "vue3-datepicker";
import JSZip from "jszip";

export default {
  components: { datepicker },
  setup() {
    const isLoading = ref(false);
    const error = ref("");
    const hasError = computed(() => error.value.length > 0);
    const providers = ref([] as any[]);
    const columns = ref([] as string[]);

    const rundate = ref(new Date());

    const diagsJson = ref([] as any[]);

    const getReport = async () => {
      isLoading.value = true;
      try {
        const result = await ApiInterface.getWeeklyReport(rundate.value);
        (window as any).raw = result;
        const weeklyReports = (result as any)[0] as ProviderWeeklyReport[];
        diagsJson.value = (result as any)[1] as any[];

        const newProviders = [];
        const newColumns = new Set<string>();

        for (const provider of weeklyReports) {
          for (const prop of Object.getOwnPropertyNames(provider)) {
            newColumns.add(prop);
          }

          newProviders.push(provider);
        }

        providers.value = newProviders;
        columns.value = [...newColumns];
      } catch (e) {
        console.log("Catching");
        console.log(e);
        (window as any).e = e;
        error.value = (e as Error)?.message ?? "An unexpected error occurred.";
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(getReport);

    const download = async () => {
      const csv = parse(providers.value);
      const diagsCsv = parse(diagsJson.value);

      const zip = new JSZip();
      zip.file("weekly_report.csv", csv);
      zip.file("diags.csv", diagsCsv);
      zip.generateAsync({ type: "blob" }).then((content) => {
        FileSaver.saveAs(content, "weekly_report.zip");
      });
    };

    return {
      columns,
      providers,
      error,
      hasError,
      isLoading,
      getReport,
      download,
      rundate,
    };
  },
};
</script>

<style scoped>
.controls {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  padding: 2rem 1rem;
  background-color: white;
  box-shadow: 00px 5px 5px black;
}

.generate-area {
  display: flex;
}

.generate-area > label {
  margin-right: 0.5rem;
}

.content {
  padding: 1rem;
  max-width: 100vw;
  overflow: scroll;
}
</style>
